import { render, staticRenderFns } from "./PlayerControls.vue?vue&type=template&id=3372d6a2&scoped=true&"
import script from "./PlayerControls.vue?vue&type=script&lang=js&"
export * from "./PlayerControls.vue?vue&type=script&lang=js&"
import style0 from "./PlayerControls.vue?vue&type=style&index=0&id=3372d6a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3372d6a2",
  null
  
)

export default component.exports